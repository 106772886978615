<template>
  
    <el-dialog title=""
        :visible.sync="visible"
        width="900px"
        :close-on-click-modal="false"
        :before-close="handleClose"
        class="upload_box">
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules"   label-width="200px">

                <el-form-item label="名称" prop="name">
                    <el-input  v-model="ruleForm.name"  />
                </el-form-item>

                <el-form-item label="模型代码" prop="modelCode">
                    <el-input  v-model="ruleForm.modelCode"  />
                </el-form-item>

                <el-form-item label="工具模型代码" prop="toolModelCode">
                    <el-input  v-model="ruleForm.toolModelCode"  />
                </el-form-item>

                <el-form-item label="成本价格(元)" prop="price">
                    <el-input  v-model="ruleForm.price"  />
                </el-form-item>
                
                <el-form-item label="排序" prop="sort">
                    <el-input  v-model="ruleForm.sort"  />
                </el-form-item>



            </el-form>
        <!-- 表单结束 -->
        <!--操作按钮-->
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary"  @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
  
  </template>
  
  <script>
  import { isNumber, isNumeric } from '@/utils/utils';

  // 引入组件及方法
  // 引入组件
  export default {
    components: {  },
    // 数据
    data() {
        var nuberCheck = (rule, value, callback)=>{
            if (value === '' || value === null) {
                return callback(new Error('不能为空'));
            }
            console.log("==========", value);
            console.log("==========", value.indexOf);

            if((value+'').indexOf(".") >= 0){
                return callback(new Error('不能有小数点'))
            }
            
            var bool = isNumeric(value)
            if(bool){
                var num = parseInt(value)
                if(num < 0){
                    return callback(new Error('数值不能低于0'))
                }
                callback()
            }else{
                callback(new Error('只能输入数字'))
            }
        }

      return {
        ruleForm:{
            name:"",
            modelCode:"",
            toolModelCode:"",
            price:0,
            sort:0
        },
        proId:"",
        rules: {
            
            name: [{ required: true, message: '请输入', trigger: 'change' }],
            modelCode: [{ required: true, message: '请输入', trigger: 'change' }],
            toolModelCode: [{ required: false, message: '请输入', trigger: 'change' }],
            price: [{ required: true, message: '请输入', trigger: 'change' }],
            sort: [{ required: true, message: '请输入', trigger: 'change' }]

        }
      }
    },
    props:{
        visible:Boolean
    },
    watch: {
        visible(){
            if(this.visible){




            }
        }
    },
    //  初始化
    mounted() {
      
    },
  
    methods: {

        start(obj, id){
            if(obj){
                this.ruleForm.modelCode = obj.modelCode
                this.ruleForm.name = obj.name
                this.ruleForm.toolModelCode = obj.toolModelCode
                this.ruleForm.price = obj.price
                this.ruleForm.sort = obj.sort

                this.proId = id
            }
        },
        handleClose(){
            this.$emit('update:visible', false)
        },
        handleSubmit(){

            this.$refs['ruleForm'].validate(async (valid) => {
                if (valid) {
                    
                    var param = {id:this.proId, ...this.ruleForm}
                    param.price = param.price * 10000
                    this.$https('updateModel', param).then(res => {
                        this.handleClose()
                        this.$emit('ok')
                    })
                    
                } else {
                    
                }
            });
            


        }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  
.illustrate{
    color: #f55;
    position: relative;
    left: -155px;
    top: -20px;
    font-size: 12px;
    width: 150px;
}
  .input1{
    width: 100px;
  }
  </style>
  