<template>
    <div>
        <el-table :data="tableData" style="width: 100%" border>

            <el-table-column prop="id" label="id" width="130">
            </el-table-column>

            <el-table-column prop="name" label="名字">
            </el-table-column>

            <el-table-column prop="modelCode" label="模型代码">
            </el-table-column>

            <el-table-column prop="toolModelCode" label="工具模型代码">
            </el-table-column>

            <el-table-column prop="modelGroup" label="分组">
            </el-table-column>

            <el-table-column prop="modelValue" label="功能">
            </el-table-column>

            <el-table-column prop="price" label="成本价格(元)">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.price }}元
                    </div>
                </template>
            </el-table-column>
            
            <el-table-column prop="unitType" label="模型单位">
                <template slot-scope="scope">
                    <div>
                        <span v-for="(item,index) in unitType" :key="index">
                            <span v-if="scope.row.unitType==item.type">{{ item.number+item.name }}</span>
                        </span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">

                    <el-button @click.stop="editBtn(scope.row)" type="text" size="small">
                        编辑
                    </el-button>

                </template>
            </el-table-column>

        </el-table>

        <EditModel :visible.sync="EditModelVisible" @ok="getData" ref="EditModelRef"></EditModel>

    </div>
</template>

<script>

import _ from 'lodash'
import EditModel from "./EditModel.vue"


export default {
    components: { EditModel },
    data() {
        return {
            EditModelVisible: false,
            tableData: [],
            unitType:[
                {type:1,name:'次',number:1},
                {type:2,name:' TOKEN',number:1000},
                {type:3,name:'字',number:100},
                {type:4,name:'秒',number:60}
            ]
        };
    },
    async mounted() {


        this.getData()


    },
    methods: {
        getData() {

            this.$https('getModels', {}).then(res => {

                // res.data.records.forEach((val)=>{
                //     Object.assign(val, val.goodsVip)
                // })
                this.tableData = res.data
                this.tableData.forEach((val) => {
                    val.price = val.price / 10000
                })


                // this.total = parseInt(res.data.total)


            })
        },
        addClickBtn() {
            this.AddMoneyPayVisible = true
            this.$nextTick(() => {
                this.$refs.AddMoneyPayRef.start()
            })
        },
        handleSizeChange(size) {
            this.pageSize = size
            this.getData()
        },
        handleCurrentChange(num) {
            this.pageNumber = num
            this.getData()
        },
        editBtn(item) {

            this.EditModelVisible = true
            this.$nextTick(() => {
                this.$refs.EditModelRef.start(item, item.id)
            })

        }

    },
};
</script>

<style scoped>
.dialogWrap {
    padding: 20px 0 20px 80px;
    box-sizing: border-box;

}

.radioWrap {
    padding: 10px 0;
}

.yes {
    color: rgb(75, 167, 0);
}

.no {
    color: rgb(255, 79, 79);
}
</style>